import 'jspdf-autotable'
import { jsPDF } from "jspdf"
import Loading from '@/components/widgets/Loading.vue'

export default {
  data() {
    return {
      allResult: null,
      loading: false,
      products: [],
      discount: 0.,
      nettotal: 0
    }
  },
  components: {
    Loading
  }, 
  mounted() {
    this.$nextTick(() => {
      this.onInitData()
    })
  },
  methods: {
    getBrandName(element) {
      return element.detail.brand !== "OT"
        ? element.detail.brand
        : element.detail.if_other_brand;
    },
    calculator(qty, price) {
      return parseInt(qty) * price
    },
    async onInitIFrame() {
      this.loading = true
      setTimeout(async () => {
        const doc = new jsPDF('p','mm','a4')
        await this.onInitDocument(doc)
        let element = document.getElementById('invoice-order')
        if (element) {
          element.setAttribute("src", doc.output('bloburl'))
          setTimeout(() => {
            this.loading = false
            this.onIframePrint()
          }, 1500)
        }
      }, 1000)
    },
    async onInitData() {
      let localApp = this
      this.busy = true
      this.loading = true
      try {
        const param = {
          _id: this.$route.params.id,
        };
        const result = await this.$store.dispatch(
          "Documents/getInvoiceDocument",
          param,
          { root: true }
        );
        this.busy = false
        this.loading = false
        
        if (result?.data) {
          localApp.allResult = result.data
          this.products = result.data.products
          let discount = 0
          this.nettotal = 0
          if (localApp.allResult.data.preemption_document_id) {
            //Calculate price            
            for (let index = 0; index < result.data.products.length; index++) {
              const element = result.data.products[index]
              
              const price = this.allResult.data.invoice_type === 1 ? element.detail.price : element.detail.sale_price
              const nw = element.detail?.net_weight
              const qty = element.detail?.quantity
              discount = element.detail?.discount
              if (!localApp.allResult.data.preemption_document_id) {
                this.nettotal += parseFloat(price*qty) - discount
              } else {
                this.nettotal += parseFloat(price*nw)
              }
            }
          } else {
            const pItem = result.data.products[0]
            for (let index = 0; index < pItem.detail.length; index++) {
              const element = pItem.detail[index]

              const price = this.allResult.data.invoice_type === 1 ? element.price : element.sale_price
              const nw = element?.net_weight
              const qty = element?.quantity
              discount = element?.discount
              if (!localApp.allResult.data.preemption_document_id) {
                this.nettotal += parseFloat(price*qty) - discount
              } else {
                this.nettotal += parseFloat(price*nw)
              }
            }
          }
        }
      } catch (error) {
        this.loading = false
        this.busy = false
        const regexPattern = /(price)|(net_weight)+/g
        const matches = error.message.match(regexPattern)
        let message = null
        if (matches) {
          message = "ไม่ได้ระบุจำนวนเงินหรือยอดรวมสินค้ากรุณาตรวจสอบ!"
        }
        this.onExceptionHandler(message)        
      }
    },
    onIframePrint() {
      let iFrame = document.getElementById("invoice-order")
      iFrame.focus()
      iFrame.contentWindow.print()
    },
    onInitDocument(doc) {
      return new Promise(async(resolve, reject) => {
        try {
          doc.addFileToVFS(
            'THSarabunNew.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunNormal
          )
          // add custom font to file
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal')
          doc.setFont('THSarabunNew', 'normal')

          doc.addFileToVFS(
            'THSarabunNew Bold.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunBold
          )
          // add custom font to file
          doc.addFont('THSarabunNew Bold.ttf', 'THSarabunNew', 'bold')
          doc.setFont('THSarabunNew', 'bold')

          const head = await this.onInitHeader()
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 0, left: 8, right: 8 },
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            head: head,
            willDrawCell (data) {
              if (data.cell.raw && data.section === 'head') {
                if (data.cell.raw.content === 'breaklineheader') {
                  const width = doc.internal.pageSize.getWidth() - 16
                  data.doc.setLineWidth(0.1)
                  data.doc.setDrawColor(220, 220, 220)
                  data.doc.line(data.cell.x, data.cell.y + 5, data.cell.x + width, data.cell.y + 5)
                }
              }
            }
          })

          const headerTable = await this.onInitHeaderTable()
          const body = await this.onInitBody(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 0, left: 8, right: 8 },
            startY: doc.lastAutoTable.finalY,
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            head: headerTable,
            body: body
          })
          const sub_footer = await this.onInitSubFooter(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 8, left: 8, right: 8 },
            startY: doc.lastAutoTable.finalY,
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            foot: sub_footer
          })

          const footer = await this.onInitFooter(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 8, left: 8, right: 8 },
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: { },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'bottom',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            foot: footer,
            willDrawCell (data) {
              if (data.cell.raw && data.section === 'foot') {
                if (data.cell.raw.content === 'issuer') {
                  data.doc.setLineWidth(0.2)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 25, data.cell.y + 20, data.cell.x + 70, data.cell.y + 20)
                }
                if (data.cell.raw.content === 'approved' ) {
                  data.doc.setLineWidth(0.2)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 27, data.cell.y + 20, data.cell.x + 72, data.cell.y + 20)
                }
              }
            }
          })
          resolve(doc)
        } catch (error) {
          reject(error)
        }
      })
    },
    onInitHeader() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: 'ใบแจ้งหนี้',
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'Invoice',
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 22,
                textColor: [15, 23, 42],
                cellPadding: {
                  top: -1,
                  bottom: 5,
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: this.allResult?.setting.company_name,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'เลขที่เอกสาร:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: `IV-${this.allResult?.data.document_no}`,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            { 
              content: this.allResult.setting.address.replace(/[\n\t\r ]+/g, ' ').trim(),
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'วันที่:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: new Date(this.allResult?.data.created_at).toLocaleDateString("th-TH", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `เลขประจำตัวผู้เสียภาษี: ${this.allResult?.setting.tax_number}`,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'เลขที่ใบสั่งซื้อ PO:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.allResult?.data?.po ? 'PO-'+this.allResult?.data.po.document_no: '',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `ติดต่อ: ${this.allResult?.setting.phone_number}`,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'รถคันที่:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.allResult?.data?.po ? this.allResult.data.po.license_plate: '',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `Fax: ${this.allResult?.setting.fax_number && this.allResult?.setting.fax_number !== 'null'?
              this.allResult?.setting.fax_number: ''}`,
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 3
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `ลูกค้า: ${this.allResult?.data?.branch_name? this.allResult?.data?.branch_name?.name: this.allResult?.data?.customer?.detail?.name}`,
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: (this.allResult?.data?.branch_name? this.allResult?.data?.branch_name?.address: this.allResult?.data?.customer?.detail?.address).replace(/[\n\t\r ]+/g, ' ').trim(),
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `ติดต่อ: ${this.allResult?.data?.branch_name? this.allResult?.data?.branch_name?.phone_number: this.allResult?.data?.customer?.detail?.phone_number}`,
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'wrap',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: -1
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            }
          ],
          [
            {
              content: 'breaklineheader',
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [255, 255, 255],
                cellPadding: {
                  bottom: 3
                },
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle'
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitHeaderTable() {
      return new Promise(async (resolve, reject) => {
        let header = []

        if (this.allResult.data.preemption_document_id) {
          header = [
            [
              {
                content: 'ลำดับ',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'top'
                }
              },
              {
                content: 'รหัสสินค้า/รายละเอียด',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'top'
                }
              },
              {
                content: 'น้ำหนัก',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 20,
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: 'ความยาว',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 20,
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: 'นน./ม.',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 20,
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: 'ราคา/หน่วย',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              // {
              //   content: 'ส่วนลด',
              //   styles: {
              //     lineWidth: 0.1,
              //     fillColor: [220,220,220],
              //     cellPadding: 2,
              //     cellWidth: 'auto',
              //     fontSize: 15,
              //     textColor: [15, 23, 42],
              //     fontStyle: 'normal',
              //     halign: 'right',
              //     valign: 'top'
              //   }
              // },
              {
                content: 'จำนวนเงิน',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              }
            ]
          ]
        } else {
          header = [
            [
              {
                content: 'ลำดับ',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'top'
                }
              },
              {
                content: 'รายละเอียด',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'top'
                }
              },
              {
                content: 'จำนวน',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 20,
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: 'ราคา/หน่วย',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: 'ส่วนลด',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: 'จำนวนเงิน',
                styles: {
                  lineWidth: 0.1,
                  fillColor: [220,220,220],
                  cellPadding: 2,
                  cellWidth: 'auto',
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              }
            ]
          ]
        }
        resolve(header)
      })
    },
    onInitBody(doc) {
      return new Promise(async (resolve, reject) => {
        const data = this.products    
        const body = []
        let emptyBody = {}

        if (this.allResult.data.preemption_document_id) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index]
  
            // const content = (`${element.detail ? element.detail.size : ''} ${
            //   element.detail ? parseInt(element.detail.length).toLocaleString(
            //     "th-TH"
            //   ) : ''
            // } M LOT ${
            //   element.detail ? element.detail.lot_no : ""
            // }
            // ${
            //   element.detail ? element.detail.color : ""
            // }`).replace(/[\n\t\r ]+/g, ' ').trim()
  
            let obj = {}
            obj = [
              {
                content: index + 1,
                styles: {
                  cellWidth: 15,
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'top'
                }
              },
              {
                content: "L" +
                element.detail.lot_no +
                " " +
                this.getBrandName(element) +
                " " +
                element.detail.coil +
                " " +
                element.detail.size +
                " " +
                element.detail.color,
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  cellWidth: 'auto',
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'left',
                  valign: 'top'
                }
              },
              {
                content: this.$options.filters.numberFormat(element.detail.net_weight),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: parseInt(element.detail.length).toLocaleString("th-TH") + "M",
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 23,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: (
                  parseFloat(element.detail.net_weight) /
                  parseFloat(element.detail.length)
                ).toFixed(3),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 23,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: this.$options.filters.numberFormat(this.allResult.data.invoice_type === 1 ? element.detail.price : element.detail.sale_price),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 23,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              // {
              //   content: this.$options.filters.numberFormat(element.disc),
              //   styles: {
              //     lineWidth: {
              //       left: 0.1,
              //       right: 0.1
              //     },
              //     fontSize: 15,
              //     cellWidth: 20,
              //     fillColor: [255, 255, 255],
              //     textColor: [15, 23, 42],
              //     cellPadding: {
              //       left: 2,
              //       right: 2,
              //       top: 1,
              //       bottom: 1
              //     },
              //     fontStyle: 'normal',
              //     halign: 'right',
              //     valign: 'top'
              //   }
              // },
              {
                content: this.$options.filters.numberFormat((this.allResult.data.invoice_type === 1 ? element.detail.price : element.detail.sale_price)*element.detail.net_weight),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 30,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              }
            ]
            body.push(obj)
          }
          
          emptyBody = [
            {
              content: '',
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'center',
                valign: 'middle'
              }
            },
            {
              content: '',
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'center',
                valign: 'middle'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 1,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 1,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            }
          ]
        } else {
          for (let index = 0; index < this.allResult?.products[0].detail.length; index++) {
            const element = this.allResult?.products[0].detail[index]
  
            let obj = {}
            obj = [
              {
                content: index + 1,
                styles: {
                  cellWidth: 15,
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'top'
                }
              },
              {
                content: element.description,
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  cellWidth: 'auto',
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'left',
                  valign: 'top'
                }
              },
              {
                content: this.$options.filters.numberFormat(element.quantity),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: this.$options.filters.numberFormat(this.allResult.data.invoice_type === 1 ? element.price : element.sale_price),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 23,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: this.$options.filters.numberFormat(element.discount),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 20,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              },
              {
                content: this.$options.filters.numberFormat(this.calculator(element.quantity, this.allResult.data.invoice_type === 1 ? element.price : element.sale_price)-element.discount),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1
                  },
                  fontSize: 15,
                  cellWidth: 30,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1
                  },
                  fontStyle: 'normal',
                  halign: 'right',
                  valign: 'top'
                }
              }
            ]
            body.push(obj)
          }
          
          emptyBody = [
            {
              content: '',
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'center',
                valign: 'middle'
              }
            },
            {
              content: '',
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'center',
                valign: 'middle'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 1,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 1,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            }
          ]
        }

        if (body.length <= 10) {
          const number = 10 - body.length
          for (let index = 0; index < number; index++) {
            // body.push(emptyBody)
          }
        }
        resolve(body)
      })
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 20
        const portion = width / 2

        const footer = []
        const signer_line = [
          {
            content: `issuer`,
            styles: {
              cellWidth: portion,
              fontSize: 15,
              textColor: [255, 255, 255],
              cellPadding: {
                top: 15
              },
              fontStyle: 'normal',
              halign: 'center',
              valign: 'bottom'
            }
          },
          {
            content: `approved`,
            styles: {
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [255, 255, 255],
              cellPadding: 0,
              fontStyle: 'normal',
              halign: 'center',
              valign: 'bottom'
            }
          }
        ]
        footer.push(signer_line)

        const signer_style = {
          cellWidth: 'auto',
          fontSize: 15,
          textColor: [15, 23, 42],
          cellPadding: 1,
          fontStyle: 'normal',
          halign: 'center',
          valign: 'bottom'
        }
        const signer_name = [
          {
            content: 'ผู้รับสินค้า/บริการ',
            styles: signer_style
          },
          {
            content: 'ผู้มีอำนาจลงนาม',
            styles: signer_style
          }
        ]
        footer.push(signer_name)
        
        const signer_date = [
          {
            content: `( วันที่ ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            styles: signer_style
          },
          {
            content: `( วันที่ ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            styles: signer_style
          }
        ]
        footer.push(signer_date)
        resolve(footer)
      })
    },
    onInitSubFooter(doc) {
      return new Promise((resolve, reject) => {        
        const footer = []
        let note = ''
        if (this.allResult.data.preemption_document_id) {
          note = (`กรุณาโอนเงินเข้าบัญชี ${
            this.allResult?.data?.branch_name? this.allResult?.data?.branch_name?.account_bank: ''
          }\nชื่อบัญชี ${
            this.allResult?.data?.branch_name? this.allResult?.data?.branch_name?.account_name: ''
          } เลขที่บัญชี ${
            this.allResult?.data?.branch_name? this.allResult?.data?.branch_name?.account_number: ''
          }`).replace(/[\t\r ]+/g, ' ').trim()
        } else {
          note = (`กรุณาโอนเงินเข้าบัญชี ${
            this.allResult?.data?.account_bank
          }\nชื่อบัญชี ${
            this.allResult?.data?.account_name
          } เลขที่บัญชี ${
            this.allResult?.data?.account_number
          }`).replace(/[\t\r ]+/g, ' ').trim()
        }

        const total = [{
            content: note,
            colSpan: 4,
            styles: {
              lineWidth: 0.1,
              cellPadding: 2,
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'left',
              valign: 'bottom'
            }
          },
          {
            content: 'รวมเงิน',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 43,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'middle'
            }
          },
          {
            content: this.$options.filters.numberFormat(this.nettotal),
            colSpan: 1,
            styles: {
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 30,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'middle'
            }
          }
        ]
        footer.push(total)

        // const discount = [
        //   {
        //     content: 'ส่วนลด',
        //     colSpan: 2,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [243, 244, 246],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 43,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   },
        //   {
        //     content: this.$options.filters.numberFormat(this.discount),
        //     colSpan: 1,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [255, 255, 255],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 30,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   }
        // ]

        // const afterDisc = [
        //   {
        //     content: 'มูลค่าหลังหักส่วนลด',
        //     colSpan: 2,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [243, 244, 246],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 43,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   },
        //   {
        //     content: this.$options.filters.numberFormat(this.nettotal),
        //     colSpan: 1,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [255, 255, 255],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 30,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   }
        // ]

        // const excluded = [
        //   {
        //     content: 'มูลค่าที่คำนวณภาษี',
        //     colSpan: 2,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [243, 244, 246],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 43,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   },
        //   {
        //     content: this.excludeVATFromPrice(this.nettotal, 7).toFixed(2),
        //     colSpan: 1,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [255, 255, 255],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 30,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   }
        // ]

        // const valueAddedTax = [
        //   {
        //     content: 'ภาษีมูลค่าเพิ่ม 7%',
        //     colSpan: 2,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [243, 244, 246],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 43,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   },
        //   {
        //     content: this.getVATAmount(this.nettotal, 7).toFixed(2),
        //     colSpan: 1,
        //     styles: {
        //       lineWidth: 0.1,
        //       fillColor: [255, 255, 255],
        //       cellPadding: {
        //         left: 2,
        //         right: 2,
        //         top: 1,
        //         bottom: 1
        //       },
        //       cellWidth: 30,
        //       fontSize: 15,
        //       textColor: [15, 23, 42],
        //       fontStyle: 'normal',
        //       halign: 'right',
        //       valign: 'top'
        //     }
        //   }
        // ]

        const summary = [
          {
            content: `(${this.formatAsThaiBaht(this.nettotal, false)})`,
            colSpan: 4,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'italic',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: 'ยอดรวมสุทธิ',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 43,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.$options.filters.numberFormat(this.nettotal),
            colSpan: 1,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 30,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(summary)
        resolve(footer)
      })
    }
  }
}
